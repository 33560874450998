import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ChatProvider } from './contexts/ChatContext';
import Header from './components/Header/Header';
import FileUpload from './components/FileUpload/FileUpload';
import Footer from './components/Footer/Footer';
import './styles/global.css';
import styles from './App.module.css';
import { ChatContainer } from "./components";
import { Notification } from "./components";
import BackgroundPattern from "./components/BackgroundPattern/ParticleBackground";
import PDFPreview from './components/PDFPreview/PDFPreview'; // Import the PDFPreview component

const App: React.FC = () => {
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]); // New state for uploaded files
    const [error, setError] = useState<string | null>(null);

    const handleUploadSuccess = (files: File[]) => {
        setIsFileUploaded(true);
        setUploadedFiles(files); // Store the uploaded files
    };

    const handleUploadError = (errorMessage: string) => {
        setError(errorMessage);
    };

    return (
        <ChatProvider>
            <div className={styles.app}>
                <BackgroundPattern />
                <Header />
                <main className={styles.mainContent}>
                    <CSSTransition
                        in={!isFileUploaded}
                        timeout={300}
                        classNames={{
                            enter: styles.fadeEnter,
                            enterActive: styles.fadeEnterActive,
                            exit: styles.fadeExit,
                            exitActive: styles.fadeExitActive,
                        }}
                        unmountOnExit
                    >
                        <FileUpload onUploadSuccess={handleUploadSuccess} onUploadError={handleUploadError} />
                    </CSSTransition>
                    <CSSTransition
                        in={isFileUploaded}
                        timeout={30000}
                        classNames={{
                            enter: styles.fadeEnter,
                            enterActive: styles.fadeEnterActive,
                            exit: styles.fadeExit,
                            exitActive: styles.fadeExitActive,
                        }}
                        unmountOnExit
                    >
                        <div className={styles.contentContainer}>
                            <div className={styles.pdfPreviewWrapper}>
                                <PDFPreview files={uploadedFiles} />
                            </div>
                            <div className={styles.chatWrapper}>
                                <ChatContainer />
                            </div>
                        </div>
                    </CSSTransition>
                </main>
                <Footer />
                {error && (
                    <Notification
                        message={error}
                        type="error"
                        onClose={() => setError(null)}
                    />
                )}
            </div>
        </ChatProvider>
    );
};

export default App;