// src/components/DocumentScanner/DocumentScanner.tsx

import React from 'react';
import styles from './DocumentScanner.module.css';

const DocumentScanner: React.FC = () => {
    return (
        <div className={styles.scannerContainer}>
            <div className={styles.document}>
                <div className={styles.scanLine}></div>
                <div className={styles.textLines}>
                    {[...Array(5)].map((_, index) => (
                        <div key={index} className={styles.textLine}></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DocumentScanner;