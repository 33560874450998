import React, { useState, useRef, useEffect } from 'react';
import styles from './FileUpload.module.css';
import apiService from '../../services/api.service';
import { ProcessingStatus } from '../ProcessingStatus/ProcessingStatus';
import {Notification} from "../Notification";
import {useChatContext} from "../../contexts/ChatContext";
import {TextExtractionStream} from "../TextExtractionStream";
import {DocumentScanner} from "../DocumentScanner";

interface FileUploadProps {
    onUploadSuccess: (files: File[]) => void;
    onUploadError: (error: string) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onUploadSuccess, onUploadError }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [processingStatus, setProcessingStatus] = useState<{ totalPages: number, processedPages: number } | null>(null);
    const [uploadComplete, setUploadComplete] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const uploadStartTime = useRef<number | null>(null);
    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' } | null>(null);
    const { sessionId, setUseDirectGpt } = useChatContext();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        if (event.dataTransfer.files) {
            setFiles(Array.from(event.dataTransfer.files));
        }
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            onUploadError('Моля, изберете файлове за качване.');
            return;
        }

        setIsUploading(true);
        setIsProcessing(true);
        try {
            await apiService.uploadFiles(files);
            onUploadSuccess(files);
        } catch (error) {
            onUploadError('Възникна грешка при качването на файловете. Моля, опитайте отново.');
        } finally {
            setIsUploading(false);
            setIsProcessing(false);
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isUploading && processingStatus) {
            timer = setInterval(() => {
                const elapsedTime = Date.now() - (uploadStartTime.current || Date.now());
                const estimatedProcessedFiles = Math.min(
                    processingStatus.totalPages - 1, // Keep one file unprocessed
                    Math.floor(elapsedTime / 1000) // Assume 1 file per second for estimation
                );
                setProcessingStatus(prev =>
                    prev ? { ...prev, processedFiles: estimatedProcessedFiles } : null
                );
            }, 100); // Update every 100ms for smoother animation
        }
        return () => clearInterval(timer);
    }, [isUploading, processingStatus]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isUploading) {
            timer = setInterval(async () => {
                try {
                    const status = await apiService.getProcessingStatus();
                    setProcessingStatus(status);
                    if (status.processedPages === status.totalPages) {
                        setUploadComplete(true);
                        clearInterval(timer);
                    }
                } catch (error) {
                    console.error('Error fetching processing status:', error);
                }
            }, 1000); // Poll every second
        }
        return () => clearInterval(timer);
    }, [isUploading]);

    return (
        <div className={styles.fileUploadContainer}>
            {!isProcessing && (
                <>
                    <div
                        className={`${styles.fileUploadArea} ${isDragging ? styles.dragging : ''}`}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".pdf"
                            multiple
                            className={styles.fileInput}
                        />
                        <p>Плъзнете и пуснете файлове тук или</p>
                        <button
                            className={styles.uploadButton}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            Изберете файлове
                        </button>
                    </div>
                    {files.length > 0 && (
                        <div className={styles.fileListContainer}>
                            <h3>Избрани файлове:</h3>
                            <div className={styles.fileList}>
                                {files.map((file, index) => (
                                    <div key={index} className={styles.fileName}>{file.name}</div>
                                ))}
                            </div>
                            <button
                                className={`${styles.uploadButton} ${isUploading ? styles.loading : ''}`}
                                onClick={handleUpload}
                                disabled={isUploading}
                            >
                                {isUploading ? (
                                    <>
                                        <span className={styles.spinner}></span>
                                        Качване...
                                    </>
                                ) : (
                                    'Качете файловете'
                                )}
                            </button>
                        </div>
                    )}
                </>
            )}
            {isProcessing && (
                <div className={styles.processingContainer}>
                    <h2>Извличане на текст</h2>
                    <div className={styles.contentWrapper}>
                        <div className={styles.scannerWrapper}>
                            <DocumentScanner />
                        </div>
                        <div className={styles.streamWrapper}>

                            {(isUploading || uploadComplete) && processingStatus && (
                                <ProcessingStatus
                                    totalFiles={processingStatus.totalPages}
                                    processedFiles={processingStatus.processedPages}
                                    uploadComplete={uploadComplete}
                                />
                            )}
                            {notification && (
                                <Notification
                                    message={notification.message}
                                    type={notification.type}
                                    onClose={() => setNotification(null)}
                                />
                            )}
                            <TextExtractionStream
                                isProcessing={isProcessing}
                            />
                        </div>
                        <div className={styles.scannerWrapper}>
                            <DocumentScanner />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileUpload;

