import React, { useState, useEffect } from 'react';
import styles from './PDFPreview.module.css';

interface PDFPreviewProps {
    files: File[];
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ files }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    useEffect(() => {
        if (files.length > 0 && !selectedFile) {
            setSelectedFile(files[0]);
        }
    }, [files, selectedFile]);

    useEffect(() => {
        if (selectedFile) {
            const url = URL.createObjectURL(selectedFile);
            setFileUrl(url);

            // Clean up the object URL when the component unmounts or when selectedFile changes
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [selectedFile]);

    return (
        <div className={styles.pdfPreviewContainer}>
            <div className={styles.fileList}>
                {files.map((file, index) => (
                    <div
                        key={index}
                        className={`${styles.fileItem} ${
                            selectedFile === file ? styles.selected : ''
                        }`}
                        onClick={() => setSelectedFile(file)}
                    >
                        {file.name}
                    </div>
                ))}
            </div>
            <div className={styles.previewArea}>
                {fileUrl ? (
                    <iframe
                        src={fileUrl}
                        title="PDF Preview"
                        width="100%"
                        height="500px"
                        style={{ border: 'none' }}
                        autoFocus={true}
                    />
                ) : (
                    <p>Select a file to preview</p>
                )}
            </div>
        </div>
    );
};

export default PDFPreview;
