import React, { useState, useEffect, useRef } from 'react';
import styles from './TextExtractionStream.module.css';

interface TextExtractionStreamProps {
    isProcessing: boolean;
}

interface StreamData {
    fileName: string;
    page: number;
    content: string[]; // Changed to array of strings
}

export const TextExtractionStream: React.FC<TextExtractionStreamProps> = ({ isProcessing }) => {
    const [streamData, setStreamData] = useState<StreamData[]>([]);
    const streamDataRef = useRef<StreamData[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isProcessing) {
            console.log('Adding window message event listener');

            const handler = (event: MessageEvent) => {
                if (event.data === '[DONE]') {
                    // Handle the "[DONE]" message directly
                    console.log('Processing complete');
                } else {
                    try {
                        const data = JSON.parse(event.data);

                        // Update the ref directly
                        const existingIndex = streamDataRef.current.findIndex(
                            item => item.fileName === data.fileName && item.page === data.page
                        );
                        if (existingIndex !== -1) {
                            streamDataRef.current[existingIndex].content.push(data.content);
                        } else {
                            streamDataRef.current.push({
                                fileName: data.fileName,
                                page: data.page,
                                content: [data.content],
                            });
                        }
                    } catch (error) {
                        console.error('Error parsing event data:', error);
                    }
                }
            };

            window.addEventListener('message', handler);

            return () => {
                console.log('Removing window message event listener');
                window.removeEventListener('message', handler);
            };
        }
    }, [isProcessing]);

    // Batch state updates to reduce re-renders
    useEffect(() => {
        if (isProcessing) {
            const interval = setInterval(() => {
                // Create a new array to trigger re-render
                setStreamData([...streamDataRef.current]);
            }, 500); // Update every 500ms

            return () => {
                clearInterval(interval);
            };
        }
    }, [isProcessing]);

    // Scroll to bottom when new data arrives
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [streamData]);

    if (!isProcessing) return null;

    // Group data by fileName
    const groupedData = streamData.reduce((acc, data) => {
        if (!acc[data.fileName]) {
            acc[data.fileName] = [];
        }
        acc[data.fileName].push(data);
        return acc;
    }, {} as { [fileName: string]: StreamData[] });

    return (
        <div className={styles.streamWrapper}>
            <div className={styles.streamContainer} ref={containerRef}>
                {streamData.length === 0 ? (
                    <p className={styles.waitingText}>Подкотовка на документите за извличане...</p>
                ) : (
                    Object.keys(groupedData).map((fileName) => (
                        <div key={fileName} className={styles.fileContent}>
                            <h2 className={styles.fileTitle}>{fileName}</h2>
                            {groupedData[fileName]
                                .sort((a, b) => a.page - b.page)
                                .map((data, index) => (
                                    <div key={index} className={styles.pageContent}>
                                        <h3 className={styles.pageTitle}>Страница {data.page}</h3>
                                        <pre className={styles.pageText}>{data.content.join('')}</pre>
                                    </div>
                                ))}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default TextExtractionStream;
