import React from 'react';
import styles from './ProcessingStatus.module.css';

interface ProcessingStatusProps {
    totalFiles: number;
    processedFiles: number;
    uploadComplete: boolean;
}

export const ProcessingStatus: React.FC<ProcessingStatusProps> = ({ totalFiles, processedFiles, uploadComplete }) => {
    const percentComplete = Math.round((processedFiles / totalFiles) * 100);

    return (
        <div className={styles.statusContainer}>
            <div className={styles.statusBar}>
                <div
                    className={`${styles.statusFill} ${uploadComplete ? styles.complete : ''}`}
                    style={{ width: `${percentComplete}%` }}
                ></div>
            </div>
            <p className={styles.statusText}>
                {uploadComplete
                    ? `Всички ${totalFiles} страници са обработени успешно!`
                    : `Обработени ${processedFiles} от ${totalFiles} страници (${percentComplete}%)`
                }
            </p>
        </div>
    );
};