import axios from 'axios';
import {config} from "react-transition-group";

const API_BASE_URL = 'https://ethera-app-demo.eu';

const apiService = {
    uploadFiles: async (files: File[]): Promise<void> => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        try {
            const response = await fetch(`${API_BASE_URL}/api/upload`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            const reader = response.body?.getReader();
            if (!reader) {
                throw new Error('Unable to read stream');
            }

            const decoder = new TextDecoder();
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                const lines = chunk.split('\n');
                lines.forEach(line => {
                    if (line.startsWith('data: ')) {
                        const eventData = line.slice(6);
                        const event = new MessageEvent('message', { data: eventData });
                        window.dispatchEvent(event);
                    }
                });
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    },



sendMessage: async (message: string, useDirectGpt: boolean): Promise<string> => {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/chatbot`, { query: message, useDirectGpt }, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return response.data.response;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    },

    getProcessingStatus: async (): Promise<{ totalPages: number, processedPages: number }> => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/processing-status` , {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error getting processing status:', error);
            throw error;
        }
    },
    getSessionId: async (): Promise<string> => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/session`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return response.data.sessionId;
        } catch (error) {
            console.error('Error getting session ID:', error);
            throw error;
        }
    },

    streamChatResponse: async (
        message: string,
        sessionId: string,
        useDirectGpt: boolean,
        onChunk: (chunk: string) => void
    ): Promise<void> => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/chatbot-stream`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                },
                body: JSON.stringify({ query: message, sessionId, useDirectGpt }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const reader = response.body?.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader!.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split('\n\n');

                for (const line of lines) {
                    if (line.startsWith('data:')) {
                        const data = line.slice(5).trim();
                        if (data === '[DONE]') {
                            return;  // Stream is finished
                        }
                        try {
                            const jsonData = JSON.parse(data);
                            onChunk(jsonData.content);
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error streaming chat response:', error);
            throw error;
        }
    },
};

export default apiService;